<script
    src="../../../../../../../AppData/Local/Temp/Rar$DRa14036.14120/src/app/helper/alertTimer/alert-timer.service.ts"
></script>
<template>
  <!-- The Modal -->
  <div
      id="editModal"
      class="modal"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <button
              type="button"
              class="close"
              data-dismiss="modal"
          >
            &times;
          </button>
        </div>
        <!-- Modal body -->
        <div class="">
          <!--Start-->
          <b-card-code
              title="Edit Role"
          >
            <!-- form -->
            <validation-observer ref="simpleRules">
              <b-form>
                <b-row>
                  <b-col md="12">
                    <b-form-group>
                      <label for="name">Role</label>
                      <validation-provider
                          #default="{ errors }"
                          name="Name"
                          rules="required"
                      >
                        <b-form-input
                            id="name"
                            v-model="updatedRole.name"
                            name="name"
                            :state="errors.length > 0 ? false:null"
                            placeholder=""
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="12">
                    <b-form-group>
                      <label for="permissions">Permissions</label>
                      <validation-provider
                          #default="{ errors }"
                          name="Permissions"
                          rules="required"
                      >
                        <v-select
                            name="permissions"
                            v-model="updatedRole.permissions"
                            class="parent-service"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="permissions"
                            :reduce="name => name.id"
                            label="name"
                            multiple
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>

                <!-- Modal footer -->
                <div class="modal-footer">
                  <button
                      type="button"
                      class="btn btn-primary"
                      @click.prevent="validationForm"
                  >
                    Submit
                  </button>
                </div>
              </b-form>
            </validation-observer>

            <template #code>
              {{ codeMultiple }}
            </template>
          </b-card-code>
          <!--End-->
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import BCardCode from '@core/components/b-card-code'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BFormTextarea,

} from 'bootstrap-vue'
import {
  required, email, confirmed, password,
} from '@validations'
import vSelect from 'vue-select'
import vue2Dropzone from 'vue2-dropzone'
import { codeMultiple, codeBasic } from './code'
import FormTextAreaDefault from '../../../shared/form-textarea/FormTextAreaDefault.vue'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

import ToastificationContent from '@core/components/toastification/ToastificationContent'
import adminService from '../../../services/adminService'

export default {
  components: {
    BCardCode,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BFormTextarea,
    FormTextAreaDefault,
    vSelect,
    vueDropzone: vue2Dropzone,
  },
  props: ['role'],
  data() {
    return {
      permissions: [],
      updatedRole: {
        name: '',
        permissions: []
      },
      required,
      password,
      email,
      confirmed,
      codeMultiple,
      codeBasic,
    }
  },
  mounted() {
    this.getPermissions()
    this.$watch(() => this.role, roleName => {
      this.name = roleName.name
      this.updatedRole = {
        name: roleName.name,
        permissions: roleName.permissions
      }
    })
  },
  methods: {
    getPermissions() {
      adminService.permissions()
          .then(response => {
            this.permissions = response.data.data
          })
    },
    validationForm() {
      this.$refs.simpleRules.validate()
          .then(success => {
            if (success) {
              adminService.put(this.role.id, this.updatedRole).then(response => {
                this.$emit('refresh', 'true')
                $('#editModal').modal('hide')
                if (response && response.status === 200) {
                  this.$toast({
                    component: ToastificationContent,
                    position: 'top-right',
                    props: {
                      title: 'Success',
                      icon: 'CheckIcon',
                      variant: 'success',
                      text: 'Role Is Updated Successfully !',
                    },
                  })
                }
                this.loading = false

              })
            }
          }).catch(function (error) {

        $('#editModal').modal('hide')
        this.$toast({
          component: ToastificationContent,
          position: 'top-right',
          props: {
            title: 'Success',
            icon: 'CheckIcon',
            variant: 'Error',
            text: 'Error !',
          },
        })
        return Promise.reject(error)
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

label {
  font-weight: bold;
}
.parent-service > ul{
  max-height: 7rem!important;
}
</style>
